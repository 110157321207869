import React from "react"
import Layout from "../components/share/layout"
import Arteycultura from "../components/arteycultura/";
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../components/share/footer"

const ArteyCultura = () => {
  const { allContentfulArteYCultura: { nodes: nodesBlog }, allContentfulGeneral: { nodes: nodesInfo } } = useStaticQuery(
    graphql`
      query {
        allContentfulArteYCultura(filter: {node_locale: {eq: "en-US"}, categorylink: {in: "8"},activarPublicacion: {eq: true}}, sort: {fields: fechaDeOrden, order: DESC}) {
          nodes {
            id
            titulo
            tituloDeRespaldo
            descripcion
            fecha
            enExhibicion
            imagenDeLaEntrada {
              file {
                fileName
                url
              }
              fluid (quality: 80,maxHeight: 1534){
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
              description
            }
          }
        }
        allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}) {
          nodes {
            blogSubtitulo
          }
        }
      }
    `)

  return (
    <Layout
      description="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
      title="Arte y Cultura | UPC Cultural"
      subtitile="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      metaTitle="Arte y Cultura | UPC Cultural"
      metaKeywords="exhibicion, exhibiciones, galeria, UPC, UPC Cultural"
      metaDescripcin="Entérate de las exhibiciones artísticas y culturales que han sido parte de la Galería de UPC Cultural."
    >
      <Arteycultura
        nodesBlog={nodesBlog}
        nodesInfo={nodesInfo}
      />
      <Footer />
    </Layout>
  )
}
export default ArteyCultura

